import React from "react"
import styled from "styled-components"
import BambooHRLogo from '../../images/BambooHR_logo.svg'
import below from "../../styles/media"
import { get, map } from "lodash"

const Title = styled.h2`
  color: #464646;
  font-size: 30px;
  font-weight: 600;
  letter-spacing: 0.5px;
  line-height: 36px;
  text-align: center;
`;

const WorkList = styled.div`
  border-top: 1px solid #d9dada;
  padding: 10px 0;
`;

const SubTitle = styled.h3`
  color: #464646;
  font-size: 18px;
  letter-spacing: 0.3px;
  margin-bottom: 20px;
  font-weight: 400;
  
  ${below.phone`
    padding: 0 5px;
  `}
`;

const ListItem = styled.div`
  display: flex;
  justify-content: space-between;
  min-height: 50px;
  
  ${below.phone`
    padding: 0 5px;
  `}
`;

const Name = styled.a`
  color: #464646;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0.4px;
  line-height: 36px;
  text-decoration: none;
  
  ${below.phone`
    font-size: 14px;
  `}
`
const Location = styled.div`
  opacity: 0.5;
  color: #464646;
  font-size: 16px;
  letter-spacing: 0.27px;
  line-height: 36px;
  text-align: right;
  
  ${below.phone`
    font-size: 12px;
  `}
`;

const PoweredBy = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: #464646;
  font-size: 16px;
  letter-spacing: 0.27px;
  line-height: 36px;
  text-align: right;
  border-top: 1px solid #d9dada;
  padding: 20px 0 10px 0;
  
  ${below.phone`
    justify-content: center;
  `}
  
  span {
    opacity: 0.48;
    padding-right: 8px;
  }
  
  img {
    width: 153px;
  }
`;

export const WorkAtFanhubList = props => {
  const { bamboo_jobs } = props;

    return (
      <React.Fragment>
        <Title>OPEN POSITIONS</Title>

        <WorkList>
          {map(bamboo_jobs, (jobs, department) => (
            <React.Fragment key={department}>
              <SubTitle>{department}</SubTitle>
              {map(jobs, job => (
                <ListItem key={get(job, 'id', 0)}>
                  <Name href={get(job, 'postingUrl', '')} target="_blank">
                    {get(job, 'title.label', '')}
                  </Name>
                  <Location>{get(job, 'location.label', '')}</Location>
                </ListItem>
              ))}
            </React.Fragment>
          ))}

          <PoweredBy>
            <span>Powered by</span>
            <img src={BambooHRLogo} alt="Bamboo HR logo" />
          </PoweredBy>
        </WorkList>
      </React.Fragment>
    )
}

export default WorkAtFanhubList
