import React from "react"
import { Helmet } from "react-helmet"
import styled from "styled-components"

import { PageContentsWrapper, PageLayout } from "../components/PageLayout"
import { SectionTitle } from "../components/SectionTitle"
import { WorkAtFanhubList } from "../components/WorkAtFanhubList"
import Spacer from "../components/Spacer"
import below from "../styles/media"
import { get, reduce } from "lodash"

import {
  createStaticContent,
  formatStaticContent,
  getPageTitle,
  transformToArray,
} from "../data/transform"

const CompanyDescriptionStyled = styled.div`
  max-width: 600px;
  color: #25282b;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.3px;
  line-height: 24px;
  text-align: center;
  margin: 40px auto 0 auto;

  ${below.phone`
    font-size: 12px;
    margin: 40px 5px 0 5px;
  `}
`

const FilteredJobs = jobs => {
  if (!jobs) {
    return {}
  }

  return reduce(
    jobs,
    (accumulator, job, key) => {
      const department_label = get(job, "node.department.label", "")
      const job_id = get(job, "node.id", 0)
      const department_category = get(accumulator, department_label, {})

      accumulator[department_label] = {
        ...department_category,
        [job_id]: {
          ...job.node,
        },
      }

      return accumulator
    },
    {}
  )
}

const WorkAtFanhubPage = props => {
  const locations = transformToArray(
    props.data,
    "locations",
    record => record.inFooter
  )
  const content = createStaticContent(props.data, "work_at_fanhub")
  const company_description = formatStaticContent(
    content,
    "work-at-fanhub-company-desc"
  )
  const bamboo_jobs = get(props, "data.bamboo_jobs.edges", null)

  return (
    <PageLayout
      locations={locations}
      static_content={content}
      locationLink={false}
    >
      <Helmet>
        <title>{getPageTitle(content)}</title>
      </Helmet>
      <SectionTitle
        title={formatStaticContent(content, "work-at-fanhub-title")}
        desc={formatStaticContent(content, "work-at-fanhub-desc")}
        bg_icon={"lineart1"}
      />
      <Spacer height={60} />
      <PageContentsWrapper>
        <WorkAtFanhubList bamboo_jobs={FilteredJobs(bamboo_jobs)} />
        <CompanyDescriptionStyled>
          {company_description}
        </CompanyDescriptionStyled>
      </PageContentsWrapper>
    </PageLayout>
  )
}

export default WorkAtFanhubPage
